import React from "react"

import NavBar from "../components/NavBar"
import Footer from "../components/Footer"
import Metatags from "../components/metatags"

const P404 = (location) => {
  const url = `https://speedcambrasil.com.br${location.pathname}`
  return (
    <>
      <Metatags
        title="Speedcam Brasil"
        description="Speed Cam - Rental House"
        url={url}
        image="https://speedcambrasil.com.br/logo.png"
        keywords="speed cam, rental, Phanton, Alexa Mini, Alexa 35, Innovision HD, Innovision HR, Cinemagic Revolution, Laowa 12, Laowa 24"
        language="pt-br"
      />
      <NavBar />
      <div className="container mx-auto text-center">
        <div className="h-20" />
        <h1 className="text-4xl m-4 md:text-6xl font-semibold text-sc-yellow">
          <i>404</i>
        </h1>
        <p>A página que você está buscando não foi encontrada.</p>
        <div className="h-20" />
      </div>
      <div className="h-10" />
      <div className="h-20" />
      <Footer />
    </>
  )
}

export default P404
